<template>
<div>
    <div class="header text-center">
        <b-container>
            <div class="display-2 mb-3">
                FAQs
            </div>
			<div class="lead m-0 text-muted">
                Answers to some common questions about Amino
            </div>
        </b-container>
    </div>
    <b-container class="document">
		<div class="accordion" role="tablist">
            <div class="py-4 border-bottom item" v-b-toggle.accordion-1>
                <b-row>
                    <b-col>
                        <div class="m-0 h3">What is Amino?</div>
                    </b-col>
                    <b-col cols="auto align-self-center">
						<span class="material-icons-outlined d-none state-closed">add</span>
						<span class="material-icons-outlined d-none state-open">remove</span>
                    </b-col>
                </b-row>
                <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel" class="truncated text-muted mt-4">
                    Amino is a browser extension that allows you to modify any website with CSS and see your changes in real time.
                </b-collapse>
            </div>
			<div class="py-4 border-bottom item" v-b-toggle.accordion-2>
                <b-row>
                    <b-col>
                        <div class="m-0 h3">Can't you just modify CSS within DevTools?</div>
                    </b-col>
                    <b-col cols="auto align-self-center">
						<span class="material-icons-outlined d-none state-closed">add</span>
						<span class="material-icons-outlined d-none state-open">remove</span>
                    </b-col>
                </b-row>
                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel" class="truncated text-muted mt-4">
                    Unlike modifying CSS in DevTools, the stylesheets you write with Amino are saved in a database and are applied every time the page is viewed. This has the effect of making your stylesheets permanent, so long as the stylesheet is enabled.
                </b-collapse>
            </div>
            <div class="py-4 border-bottom item" v-b-toggle.accordion-3>
                <b-row>
                    <b-col>
                        <div class="m-0 h3">Do I need to know CSS to use Amino?</div>
                    </b-col>
                    <b-col cols="auto align-self-center">
						<span class="material-icons-outlined d-none state-closed">add</span>
						<span class="material-icons-outlined d-none state-open">remove</span>
                    </b-col>
                </b-row>
                <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel" class="truncated text-muted mt-4">
                    Amino is most intuitive for those who have a basic understanding of CSS syntax and the cascading nature of CSS, but many users have found Amino to be a useful resource in learning how to write CSS.
                </b-collapse>
            </div>
            <div class="py-4 border-bottom item" v-b-toggle.accordion-4>
                <b-row>
                    <b-col>
                        <div class="m-0 h3">Why do I have to create an account to use Amino?</div>
                    </b-col>
                    <b-col cols="auto align-self-center">
						<span class="material-icons-outlined d-none state-closed">add</span>
						<span class="material-icons-outlined d-none state-open">remove</span>
                    </b-col>
                </b-row>
                <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel" class="truncated text-muted mt-4">
                    We get this question often. In previous versions of Amino we utilized Chrome's storage API to store your stylesheets. That API's storage quota was prohibitive - we received many complaints about it - and as a response we created our own platform for storing user stylesheets at aminoeditor.com. You'll need to create an account there in order to use Amino.
                </b-collapse>
            </div>

            <div class="py-4 border-bottom item" v-b-toggle.accordion-5>
                <b-row>
                    <b-col>
                        <div class="m-0 h3">Do you collect user data? Is my information private?</div>
                    </b-col>
                    <b-col cols="auto align-self-center">
						<span class="material-icons-outlined d-none state-closed">add</span>
						<span class="material-icons-outlined d-none state-open">remove</span>
                    </b-col>
                </b-row>
                <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel" class="truncated text-muted mt-4">
                    We are extremely committed to user privacy. Please read our <router-link to="/privacy">Privacy Policy</router-link> to learn about how we honor your privacy.
                </b-collapse>
            </div>

			<div class="py-4 border-bottom item" v-b-toggle.accordion-6>
                <b-row>
                    <b-col>
                        <div class="m-0 h3">Can I use Amino for free?</div>
                    </b-col>
                    <b-col cols="auto align-self-center">
						<span class="material-icons-outlined d-none state-closed">add</span>
						<span class="material-icons-outlined d-none state-open">remove</span>
                    </b-col>
                </b-row>
                <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel" class="truncated text-muted mt-4">
                    Yes! We have a free, ad-supported pricing plan that you can use indefinitely. This plan offers generous (but limited) cloud storage, free forever.
                </b-collapse>
            </div>
            <div class="py-4 border-bottom item" v-b-toggle.accordion-7>
                <b-row>
                    <b-col>
                        <div class="m-0 h3">What happens when I reach the storage limit on the free plan?</div>
                    </b-col>
                    <b-col cols="auto align-self-center">
						<span class="material-icons-outlined d-none state-closed">add</span>
						<span class="material-icons-outlined d-none state-open">remove</span>
                    </b-col>
                </b-row>
                <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel" class="truncated text-muted mt-4">
                    Once you have reached the storage limit you will need to either pare down your user data, or upgrade to a paid plan to continue to create more stylesheets.
                </b-collapse>
            </div>
            <div class="py-4 border-bottom item" v-b-toggle.accordion-8>
                <b-row>
                    <b-col>
                        <div class="m-0 h3">What are my payment options?</div>
                    </b-col>
                    <b-col cols="auto align-self-center">
						<span class="material-icons-outlined d-none state-closed">add</span>
						<span class="material-icons-outlined d-none state-open">remove</span>
                    </b-col>
                </b-row>
                <b-collapse id="accordion-8" accordion="my-accordion" role="tabpanel" class="truncated text-muted mt-4">
                    You can pay on a monthly or an annual basis. We charge per user. Debit and credit payments are accepted. Payments are powered by and secured by Stripe.
                </b-collapse>
            </div>

            <div class="py-4 border-bottom item" v-b-toggle.accordion-9>
                <b-row>
                    <b-col>
                        <div class="m-0 h3">How do I cancel my paid plan?</div>
                    </b-col>
                    <b-col cols="auto align-self-center">
						<span class="material-icons-outlined d-none state-closed">add</span>
						<span class="material-icons-outlined d-none state-open">remove</span>
                    </b-col>
                </b-row>
                <b-collapse id="accordion-9" accordion="my-accordion" role="tabpanel" class="truncated text-muted mt-4">
                    If you are paying annually, you will be notified 30 days before your annual plan is set to renew. At that time, you may choose to cancel your annual plan and switch to monthly payments. You may also choose
                    to cancel your monthly or annual plan altogether and downgrade to the free plan at any time.
                </b-collapse>
            </div>

            <div class="py-4 item" v-b-toggle.accordion-10>
                <b-row>
                    <b-col>
                        <div class="m-0 h3">But, I have more questions.</div>
                    </b-col>
                    <b-col cols="auto align-self-center">
						<span class="material-icons-outlined d-none state-closed">add</span>
						<span class="material-icons-outlined d-none state-open">remove</span>
                    </b-col>
                </b-row>
                <b-collapse id="accordion-10" accordion="my-accordion" role="tabpanel" class="truncated text-muted mt-4">
                    We’re here to help! Please email <a href="mailto:support@aminoeditor.com">support@aminoeditor.com</a>.
                </b-collapse>
            </div>
        </div>
    </b-container>
</div>
</template>

<script>
export default {
    name: 'FAQs'
}
</script>

<style lang="scss" scoped>
.header {
    padding: 5rem 0;
}
.document {
    a {
        text-decoration: underline;
    }
}
.container {
	max-width: 900px;
}

.accordion {
	.state-closed {
		color: var(--color-100);
	}
    .item {
        &.border-bottom {
            border-bottom-color: var(--color-500) !important;
        }
        &.not-collapsed {
			.state-open {
				display: block !important;
			}
            .title {
                color: white;
            }
        }
		&.collapsed {
			.state-closed {
				display: block !important;
			}
		}
    }

    .truncated {
        max-width: 650px;
    }
}
</style>
