<template>
<div class="d-flex flex-column h-100" style="min-height: 100%;">
    <div class="content flex-grow-1">
        <NavbarMain></NavbarMain>
        <div class="flex-grow-1">
			<router-view></router-view>
        </div>
    </div>
    <FooterMain></FooterMain>
</div>
</template>

<script>
import NavbarMain from '../../components/NavbarMain';
import FooterMain from '../../components/FooterMain';
export default {
    name: 'Support',
    components: {
        NavbarMain,
        FooterMain
    }
}
</script>

<style lang="scss" scoped>
	.content {
		padding-bottom: 5rem;
	}
</style>
