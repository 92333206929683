<template>
<div class="onboarding">
    <div class="header text-center">
        <div class="display-2 mb-3">
            Getting Started
        </div>
        <div class="lead m-0 text-muted">
            Simple steps to getting started with Amino.
        </div>
    </div>
    <div class="container">
        <div class="section d-flex flex-column">
            <div class="description">
                <div class="h1 mb-3">
                    Open the editor from the toolbar icon
                </div>
                <div class="mb-5 text-muted">
                    For demonstration purposes, open it while you're on this page.
                </div>
            </div>
            <div class="image">
                <div class="wrapper">
                    <img v-lazy="onboardingImage1" class="img-fluid">
                </div>
            </div>
        </div>
        <div class="section d-flex flex-column">
            <div class="description">
                <div class="h1 mb-5">Alternatively, open the editor from within DevTools</div>
                <div class="h3 mb-4 font-weight-bold">Open DevTools:</div>
                <ol class="mb-5">
                    <li>
                        Mac: <prism ref="prism" language="css" class="allow-select d-inline inline-prism" :code="'Command+Option+I'" :plugins="pluginConfigs"></prism>
                    </li>
                    <li>
                        Windows/Linux:
                        <prism ref="prism" language="css" class="allow-select d-inline inline-prism" :code="'F12'" :plugins="pluginConfigs"></prism>
                        or
                        <prism ref="prism" language="css" class="allow-select d-inline inline-prism" :code="'Control+Shift+I'" :plugins="pluginConfigs"></prism>
                    </li>
                </ol>
                <div class="h3 mb-4">
                    Select the Elements tab:
                </div>
            </div>
            <div class="image">
                <div class="mb-5">
                    <img v-lazy="onboardingImage2" class="img-fluid">
                </div>
            </div>
            <div class="w-100 description">
                <div class="h3 mb-4">
                    Then, select the Amino sub-tab:
                </div>
            </div>
            <div class="image">
                <div class="wrapper">
                    <img v-lazy="onboardingImage3" class="img-fluid">
                </div>
            </div>
        </div>
        <div class="section d-flex flex-column">
            <div class="description">
                <div class="h1 mb-4">
                    Begin composing your CSS
                </div>
                <div class="mb-4 text-muted">
                    For demonstration purposes, add this CSS to the the editor and save it while on this page:
                </div>
            </div>
            <div class="image">
                <div class="demo-stylesheet mt-4 text-left p-4">
                    <div class="copy" v-b-tooltip.left.hover :title="'Copy to Clipboard'" @click.prevent="$copyText(sampleCode); makeToast('Copied to clipboard');">
                        <span class="material-icons-outlined">copy</span>
                    </div>
                    <prism ref="prism" language="css" class="allow-select" :code="sampleCode" :plugins="pluginConfigs"></prism>
                </div>
            </div>
        </div>
        <div class="section d-flex flex-column">
            <div class="description">
                <div class="h1 mb-3">
                    That's it! Take a look at the logo in the top nav.
                </div>
                <div class="text-muted">
                    That's the gist of how to use Amino to customize sites with CSS.
                </div>
				<div class="mt-5">
					<b-button variant="primary" rel="noopener" to="/library">
						Go to Library
						<span class="material-icons-outlined">chevron_right</span>
					</b-button>
                </div>
            </div>
        </div>

        <div class="border bg-dark rounded p-5" style="border-color: var(--color-500) !important;">
            <div class="h2 font-weight-extra-bold">
                For other tutorials and how-to's, visit <a href="https://docs.aminoeditor.com" style="text-decoration: underline; color: inherit;" target="_blank">Amino Guides</a>.
            </div>
            <hr class="my-4" />
            <div class="mb-4 text-muted">
                Guides you may find helpful starting out:
            </div>
            <div class="d-flex flex-column">
                <div class="mb-1">
                    <a class="d-flex align-items-center" href="https://docs.aminoeditor.com/guides/setting-browser-hotkeys" target="_blank">
                        Setting browser hotkeys
                        <div class="arrow-button ml-3 d-flex align-items-center justify-content-center">
                            <span class="material-icons-outlined md-light">east</span>
                        </div>
                    </a>
                </div>
                <div>
                    <a class="d-flex align-items-center" href="https://docs.aminoeditor.com/guides/installing-amino-as-a-progressive-web-app-pwa" target="_blank">
                        Installing Amino as a Progressive Web App (PWA)
                        <div class="arrow-button ml-3 d-flex align-items-center justify-content-center">
                            <span class="material-icons-outlined md-light">east</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
const logo = require('../../assets/images/icon.svg');
const onboardingImage1 = require('../../assets/images/onboarding/onboarding-1.webp');
const onboardingImage2 = require('../../assets/images/onboarding/onboarding-2.webp');
const onboardingImage3 = require('../../assets/images/onboarding/onboarding-3.webp');
import {
    VuePrismComponent as prism
} from '@aminoeditor/vue-prism';
export default {
    name: 'Onboarding',
    components: {
        prism
    },
    data() {
        return {
            pluginConfigs: {
                whitespace: {
                    'remove-trailing': true,
                    'remove-indent': true,
                    'left-trim': true,
                    'right-trim': true
                }
            },
            sampleCode: "@keyframes spin {\n\tfrom { transform: rotate(0deg); }\n\tto { transform: rotate(360deg); }\n}\n\n.logo {\n\tanimation: 4000ms spin infinite;\n}",
            logo,
            onboardingImage1,
            onboardingImage2,
            onboardingImage3
        };
    }
}
</script>

<style lang="scss" scoped>
.header {
    padding: 5rem 0;
}

.onboarding {
    counter-reset: section;
    .section {
        counter-increment: section;
        &:not(:last-of-type) {
            padding-bottom: 5rem;
        }
        &::before {
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translate(calc(-50% - 3rem), 0.25rem);
            font-weight: var(--font-weight-extra-bold);
            position: absolute;
            font-size: 1.35rem;
            width: 2.75rem;
            height: 2.75rem;
            border-radius: 6rem;
            background-color: var(--color-500);
            content: counter(section);
        }
        max-width: 750px;
        margin: auto;
        @media (max-width: 992px) {
            padding: 2rem 0;
        }
        .description {
            max-width: 475px;
            .h1 {
                font-size: 2rem;
                line-height: 1.3;
            }
        }
        .image {
            max-width: 900px;
        }
    }
}

.feature-highlights {
    font-weight: var(--font-weight-bold);
    font-size: 0.875rem;
    li {
        .material-icons-outlined {
            font-size: 1.25rem;
            color: var(--color-primary);
        }
        &:not(:last-of-type) {
            margin-bottom: 0.65rem;
        }
    }
}

.cta {
    background-color: var(--color-700);
    .interior {
        border-bottom: 1px solid var(--color-500);
        padding: 5rem 0;
        @media (max-width: 992px) {
            flex-direction: column;
            align-items: center;
            padding: 5rem 0;
            .h1 {
                margin-bottom: 2rem;
            }
        }
    }
}

.demo-stylesheet {
    position: relative;
    background: var(--color-700);
    border-radius: 1rem;
    .copy {
        position: absolute;
        top: 0;
        right: 0;
        padding: 1rem;
        cursor: pointer;
    }
}
</style>
