import { render, staticRenderFns } from "./FooterMain.vue?vue&type=template&id=2378cd38&scoped=true&"
import script from "./FooterMain.vue?vue&type=script&lang=js&"
export * from "./FooterMain.vue?vue&type=script&lang=js&"
import style0 from "./FooterMain.vue?vue&type=style&index=0&id=2378cd38&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2378cd38",
  null
  
)

export default component.exports