<template>
<div>
	<section class="cta text-center" v-if="!isAuthenticated">
		<b-container>
			<div class="interior d-flex justify-content-between">
				<div class="h1">
					Ready to get started?
				</div>
				<div class="d-flex">
					<div class="mr-3">
						<b-button variant="primary" rel="noopener" :href="getExtensionLink('chrome')" target="_blank">
							Add To Chrome
							<span class="material-icons-outlined">chevron_right</span>
						</b-button>
					</div>
					<div>
						<b-button variant="outline-dark" rel="noopener" to="/faqs">
							Learn More
							<span class="material-icons-outlined">chevron_right</span>
						</b-button>
					</div>
				</div>
			</div>
		</b-container>
	</section>
	<footer class="site-footer position-relative">
		<b-container>
			<div class="d-block d-md-flex justify-content-between">
				<div class="logo-wrapper">
					<router-link to="/">
						<img v-lazy="logo" height="28" alt="Amino Logo" />
					</router-link>
				</div>
				<div class="menu text-muted">
					<div class="column">
						<div class="item">
							<router-link to="/">
								Home
							</router-link>
						</div>
						<div class="item">
							<a href="https://chrome.google.com/webstore/detail/pbcpfbcibpcbfbmddogfhcijfpboeaaf" target="_blank">
								Add to Chrome
							</a>
						</div>
						<div class="item">
							<router-link to="/pro">
								Free Version
							</router-link>
						</div>
						<div class="item">
							<router-link to="/pro">
								Pricing
							</router-link>
						</div>
						<div class="item">
							<a href="https://www.bonfire.com/store/amino" target="_blank">
								Store
							</a>
						</div>
					</div>
					<div class="column">
						<div class="item">
							<router-link to="/faqs">
								FAQs
							</router-link>
						</div>
						<div class="item">
							<router-link to="/onboarding">
								Getting Started
							</router-link>
						</div>
						<div class="item">
							<router-link to="/status">
								Status
							</router-link>
						</div>
						<div class="item">
							<a href="https://docs.aminoeditor.com" target="_blank">
								Docs
							</a>
						</div>
						<!-- <div class="item">
							<router-link to="/tools/rem-converter">
								REM Converter
							</router-link>
						</div> -->
					</div>
					<div class="column">
						<div class="item">
							<router-link to="/privacy">
								Privacy
							</router-link>
						</div>
						<div class="item">
							<router-link to="/terms">
								Terms
							</router-link>
						</div>
						<div class="item">
							<router-link to="/permissions">
								Permissions
							</router-link>
						</div>
						<div class="item">
							<router-link to="/advertising">
								Advertising
							</router-link>
						</div>
					</div>
					<div class="column">
						<div class="item">
							<a href="https://twitter.com/aminoeditor" target="_blank">
								Twitter
							</a>
						</div>
						<div class="item">
							<a href="https://github.com/aminoeditor" target="_blank">
								GitHub
							</a>
						</div>
						<div class="item">
							<a href="https://www.linkedin.com/company/aminoeditor" target="_blank">
								LinkedIn
							</a>
						</div>
					</div>
				</div>
			</div>
			<div class="sub-footer small d-flex justify-content-between">
				<div class="text-muted">
					&copy; {{new Date().getFullYear()}} Amino. All rights reserved.
				</div>
				<!-- <div class="d-none d-md-block text-muted">
				Made in America
			</div> -->
			</div>
		</b-container>
	</footer>
</div>
</template>

<script>
const logo = require('../assets/images/icon.svg');
export default {
	name: 'FooterMain',
	data() {
		return {
			logo
		}
	}
}
</script>

<style lang="scss" scoped>
.container {
	max-width: 1200px;
	padding-left: 2rem;
	padding-right: 2rem;
}

.logo-wrapper {
	@media (max-width: 768px) {
		margin-bottom: 3rem;
	}
}

.site-footer {
	background-color: var(--color-700);
	padding: 3rem 0 2rem;
	overflow: hidden;
	.menu {
		display: grid;
		grid-column-gap: 7rem;
		grid-template-columns: auto auto auto auto;

		@media (max-width: 692px) {
			grid-column-gap: 7rem;
			grid-template-columns: auto auto auto;
		}

		@media (max-width: 550px) {
			grid-column-gap: 7rem;
			grid-template-columns: auto auto;
		}

		.item {
			a {
				font-size: 0.875rem;
				color: var(--color-100);
				&:hover {
					color: var(--color-white);
				}
			}
			&:not(:last-of-type) {
				margin-bottom: 0.25rem;
			}
			@media (max-width: 692px) {
				margin-bottom: 0.25rem;
			}
		}
	}

	.sub-footer {
		border-top: 1px solid var(--color-500);
		padding-top: 2rem;
		margin-top: 3rem;
		a {
			text-transform: uppercase;
			color: inherit;
		}
	}
}

.cta {
	background-color: var(--color-700);
	.interior {
		border-bottom: 1px solid var(--color-500);
		padding: 5rem 0;
		@media (max-width: 992px) {
			flex-direction: column;
			align-items: center;
			padding: 5rem 0;
			.h1 {
				margin-bottom: 2rem;
			}
		}
	}
}
</style>
