<template>
<div class="onboarding">
	<div class="header text-center">
		<div class="display-2 mb-3">
			Features
		</div>
		<div class="lead m-0 text-muted">

		</div>
	</div>
</div>
</template>

<script>
export default {
	name: 'Features'
}
</script>

<style lang="scss" scoped>

</style>
